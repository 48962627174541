import axios from 'axios';
import store from '../store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;


axios.interceptors.request.use(async function (config) {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };

  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalConfig = error.config;
  const { url } = error.config;

  if (error.response && url !== '/login') {
    switch (error.response.status) {
    case 401:
      console.log('1)обнаружена ошибка запроса');
      localStorage.removeItem('access_token');
      if (!store.getters.pendingUpdate_RefreshTokens) {
        store.dispatch('refreshTokens');
      }

      await store.getters.pendingUpdate_RefreshTokens;
      console.log('повторный запрос', originalConfig);

      return axios(originalConfig);
    case 503:
      router.push('/503');
      break;
    }
  }

  return Promise.reject(error);
});

export default axios;
