<template>
    <div class="pagination">
        <div class="pagination__spacer" />
        <div class="pagination__controls">
            <button
                    class="pagination__arrow pagination__arrow--left"
                    @click="setPage(options.page - 1)"
                    :disabled="options.page <= 1"
            >
                <svg>
                    <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
                </svg>
            </button>
            <button
                    v-show="options?.page > 20 + maxPageOffset"
                    @click="setPage(options?.page - 20)"
                    :class="['pagination__number', 'pagination__number__peref', {'pagination__number_selected': options?.page === 1}]"
            >
                {{ options?.page - 20 }}
            </button>
            <div class="pagination__numbers">
                <button
                        v-for="number in pageNumbers"
                        :key="number"
                        @click="setPage(number)"
                        :class="['pagination__number', (options?.page === number && 'pagination__number_selected')]"
                        :disabled="number == options?.page"
                >
                    {{ number }}
                </button>
            </div>
            <button
                    v-show="options?.page + 20 < meta?.pageCount"
                    @click="setPage(options?.page + 20)"
                    :class="['pagination__number', 'pagination__number__peref', {'pagination__number_selected': options?.page === 1}]"
            >
                {{ options?.page + 20 }}
            </button>
            <button
                    class="pagination__arrow"
                    @click="setPage(options.page + 1)"
                    :disabled="options.page >= meta.pageCount"
            >
                <svg>
                    <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + '#arrow'}" />
                </svg>
            </button>
        </div>
        <div class="pagination__take">
            <select
                    :value="options?.take"
                    @change="(event) => setTake(parseInt(event.target.value))"
            >
                <option
                        v-for="option in takeOptions"
                        :key="option"
                        :value="option"
                >
                    {{ option }}
                </option>
            </select>
        </div>
    </div>
</template>


<script>
export default {
  props: {
    options: Object,
    meta: Object,
  },
  data () {
    return {
      maxPageOffset: 5,
      takeOptions: [
        5,
        10,
        20,
        30,
        40,
        50,
      ],
    };
  },
    methods: {
        updateOptions (newOptions) {
            this.$emit('options-change', newOptions);
        },
        setPage(page) {
            if (page >= 1 && page <= this.meta.pageCount) {
                this.updateOptions({ ...this.options, page });
            }
        },
        setTake(take) {
            this.updateOptions({ ...this.options, take });
        },
    },
    computed: {
        pageNumbers() {
            const pageCount = this.meta.pageCount;
            const currentPage = this.options.page;
            const range = 2; // Показываем 2 страницы до и после текущей

            // Меньше страниц на мобильных устройствах
            const visibleRange = window.innerWidth < 768 ? 1 : range;

            let startPage = Math.max(1, currentPage - visibleRange);
            let endPage = Math.min(pageCount, currentPage + visibleRange);

            const numbers = [];
            for (let i = startPage; i <= endPage; i++) {
                numbers.push(i);
            }

            return numbers;
        },
    },

};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: white;
  border-radius: 16px;
  padding: 0 16px;
  box-shadow: $sections_shadow;
  flex-wrap: wrap;  // Добавляем перенос элементов на новые строки

  & > * {
    flex: 1;
    min-width: 0;  // Это поможет избежать растягивания на маленьких экранах
  }

  &__controls {
    display: flex;
    align-items: center;
    gap: 24px; // Уменьшаем промежутки между элементами
    flex-wrap: wrap; // Это позволит элементам переноситься, если места не хватает
    justify-content: center; // Центрируем элементы на мобильных устройствах
  }

  &__arrow {
    border: none;
    padding: 0;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 8px;

    svg {
      height: 13px;
      width: 13px;
    }

    &--left {
      transform: rotate(180deg);
    }
  }

  &__numbers {
    display: flex;
    align-items: center;
    gap: 8px; // Уменьшаем расстояние между номерами страниц на мобильных устройствах
    flex-wrap: wrap;  // Чтобы номера могли переноситься
    justify-content: center;
  }

  &__number {
    cursor: pointer;
    padding: 0 8px;
    background: none;
    border: none;
    font-size: 1.125rem;
    line-height: 1;
    text-align: center;

    &_selected {
      color: $red;
    }
  }

  &__take {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    select {
      cursor: pointer;
      background: none;
      border: none;
      color: $red;
      font-size: 1.125rem;
      line-height: 1;
      text-align: center;
    }

    &:focus {
      outline: none;
    }
  }

  // Медиазапрос для мобильных устройств (например, экраны с шириной <= 768px)
  @media (max-width: 768px) {
    .pagination {
      flex-direction: column; // Переводим пагинацию в колонку
      height: auto;
      padding: 12px;
      gap: 8px; // Уменьшаем отступы между элементами
    }

    .pagination__controls {
      gap: 12px; // Уменьшаем расстояния между кнопками
      justify-content: center; // Центрируем элементы
    }

    .pagination__numbers {
      gap: 8px; // Уменьшаем промежутки между номерами
      justify-content: center;
    }

    .pagination__take {
      width: 100%;
      justify-content: center;
    }

    .pagination__number {
      font-size: 1rem; // Уменьшаем размер шрифта
    }
  }

  // Медиазапрос для очень маленьких экранов (например, телефоны в портретном режиме)
  @media (max-width: 480px) {
    .pagination__numbers {
      display: none; // Скрываем номера страниц на маленьких экранах, чтобы упростить интерфейс
    }

    .pagination__arrow {
      svg {
        width: 10px; // Уменьшаем размер стрелок
        height: 10px;
      }
    }

    .pagination__take select {
      font-size: 0.875rem; // Уменьшаем размер шрифта для select
    }
  }
}
</style>
