<template>
  <div class="empty-text">
    <p>
      {{ text }}
    </p>
    <Tooltip class="tooltip" v-show="tooltip">{{tooltip}}</Tooltip>
  </div>
</template>
<script>
import Tooltip from "./Tooltip.vue";
export default {
  components: {
    Tooltip,
  },
  props: {
    text: String,
    tooltip: String,
  },
};
</script>
<style lang="scss" scoped>
.empty-text {
  display: flex;
  align-items: center;
  color: #d6d6d6;
  user-select: none;
  position: relative;

  p {
    padding: 0;
    margin: 0;
  }

  .tooltip {
    position: absolute;
    top: -40px;
    left: -50%;
    transform: translateX(-40%);
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}
</style>