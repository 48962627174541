<template>
  <div class="wrapper">
    <header class="header">
      <div class="header__container _container">
        <!-- Логотип -->
        <img class="header__logo" src="@/assets/MyLabelOfferLogo.png" alt="Logo">

        <!-- Кнопка для открытия/закрытия меню на мобильных -->
        <button class="header__toggle" @click="toggleMenu">
          <span v-if="!isMenuOpen">☰</span> <!-- Иконка сэндвича -->
          <span v-else>✖</span> <!-- Иконка закрытия -->
        </button>

        <!-- Основное меню -->
        <div class="header__menu" :class="{ 'header__menu--open': isMenuOpen }">
          <nav @click="scrollTo('distribution', -15); closeMenu()" class="header__nav">Дистрибуция и магазины</nav>
          <nav @click="scrollTo('monetization', -20); closeMenu()" class="header__nav">Монетизация и цены</nav>
          <nav @click="scrollTo('footer'); closeMenu()" class="header__nav">Вопросы и контакты</nav>
        </div>

        <!-- Кнопка "Вход" как текст для десктопа и иконка для мобильных -->
        <div class="header__actions">
          <router-link class="header__login" to="/signin" aria-label="Вход">
            ВХОД
          </router-link>
          <router-link class="header__login--icon" to="/signin" aria-label="Вход">
            🔒 <!-- Иконка входа -->
          </router-link>
        </div>
      </div>
    </header>

    <section class="banner banner--head">
      <div class="banner__inner-wrapper banner--head__inner-wrapper">
        <h1 class="banner__header">
          Не первый год работаем с ребятами из My Label.<br>Абсолютно довольны всем, а именно отношением<br>и подходом  к реализации задуманных нами планов.
        </h1>
        <div class="banner__listen">
          <p class="banner__artist">
            {{ headBanner.artist }}
          </p>
          <p class="banner__source">
            {{ headBanner.source }}
          </p>
          <button class="banner__listen-button">
            <svg>
              <use v-bind="{'xlink:href' : require('@/assets/icons/all_icons.svg') + headBanner.buttonIcon}" />
            </svg>
            <span>
              {{ headBanner.buttonText }}
            </span>
          </button>
        </div>
        <p class="banner__text">
          Легко и быстро загружайте свою музыку на все цифровые витрины<br>по всему миру, как это делает slowme вместе с My Label
        </p>
        <div class="banner__actions">
          <div class="banner__action">
            <RedButton
              class="banner__button"
              @click="scrollTo('subscriptions')"
            >
              Начать
            </RedButton>
          </div>
        </div>
      </div>
    </section>

    <section
      id="distribution"
      class="glasses glasses--small"
    >
      <div class="glasses__container _container">
        <div class="glasses__row">
          <Glass
            class="glass"
            :name="'SERVICE'"
            :label="'Сервис'"
            :header="'Оставайтесь независимыми!'"
          >
            Создайте свой собственный музыкальный лейбл
          </Glass>

          <Glass
            class="glass"
            :name="'DISTRIBUTION'"
            :label="'Дистрибуция'"
            :header="'НЕТ НИЧЕГО ПРОЩЕ!'"
          >
            Всего несколько шагов для того, чтобы ваша музыка оказалась на всех цифровых витринах
          </Glass>

          <Glass
            class="glass"
            :name="'REGIONS'"
            :label="'Регионы'"
            :header="'везде и всюду'"
          >
            Вместе с MyLabel вашу музыку смогут услышать в более чем 100 странах мира
          </Glass>
        </div>
        <div class="glasses__row">
          <Glass
            class="glass glass--wide"
            :name="'PLATFORMS'"
            :label="'Цифровые витрины'"
            :header="'на любой вкус'"
          >
            <p>Мы доставим вашу музыку на десятки и даже сотни изветных стриминг сервисов, среди которых:</p>
            <div class="glass__platforms">
              <img
                :src="require(`@/assets/services/${platform.slice(2, platform.length)}`)"
                v-for="platform in require.context('@/assets/services', true).keys()"
                :key="platform"
              >
            </div>
            <p>... и ещё множество других платформ</p>
          </Glass>

          <Glass
            class="glass"
            :name="'COPYRIGHT'"
            :label="'Монетизация'"
            :header="'Лучшая техподдержка'"
          >
            Получите все преимущества музыкального лейбла, оставаясь свободным
          </Glass>
        </div>
      </div>
    </section>

    <section class="banner banner--ui interface">
      <div class="banner--ui__inner-wrapper__container _container-narrow">
        <div class="banner__inner-wrapper banner--ui__inner-wrapper">
          <h1 class="banner__header">
            Адаптивный user-friendly интерфейс.
          </h1>
          <p class="banner__text">
            Вы легко разберётесь с тем, как пользоваться нашим сервисом на любом устройстве
          </p>
          <div class="banner__actions">
            <div class="banner__action">
              <RedButton
                class="banner__button"
                @click="scrollTo('subscriptions')"
              >
                Начать
              </RedButton>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="monetization"
      class="glasses glasses--big"
    >
      <div class="glasses__container _container">
        <div class="glasses__row">
          <Glass
            class="glass"
            :name="'MONETIZATION'"
            :label="'Монетизация'"
            :header="'Хочешь получить больше?'"
            :link="'#'"
            :linkTitle="'Подробнее'"
          >
            <p>
              По подписке вы получаете 100% от дохода от ваших стримов, ускоренную отправку на стримминговые платформы, моментальную поддержку 24/7 и многое другое!
            </p>
          </Glass>

          <Glass
            class="glass"
            :name="'PRICES'"
            :label="'Цены'"
            :header="'Бесплатная загрузка'"
          >
            <p>
              Сервис бесплатный, но всегда есть возможность получить больше
            </p>
          </Glass>
        </div>
      </div>
    </section>

    <footer
      id="footer"
      class="footer"
    >
      <div class="footer__container _container-narrow">
        <div class="footer__info">
          <div class="footer__documents">
            <a
              href="https://mylabel.cc/files/public_offer.pdf"
              target="_blank"
            >Read public offer</a>
            <p>
              By using this service, you agree to the use of <a
                href=""
                target="_blank"
              >cookies</a>
            </p>
          </div>
          <p class="footer__copyright">
            Copyright © MyLabel. All rights reserved
          </p>
        </div>
        <div class="footer__social-media">
          <div class="footer__links">
            <a
              href="https://www.instagram.com/mylabel.cc/"
              target="_blank"
              class="footer__link"
            >INSTAGRAM</a>
            <a
              href="https://vk.com/mylabelcc"
              target="_blank"
              class="footer__link"
            >VK</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Glass from '@/components/Glass.vue';
import Plan from '@/components/Plan.vue';
import RedButton from '@/components/RedButton.vue';
import { LineChart } from 'vue-chart-3';

export default {
  components: {
    Glass,
    RedButton,
    LineChart,
    Plan,
    // DropDown,
  },
  data () {
    return {
      isMenuOpen: false,
      headBanner: {
        artist: 'slowme',
        source: 'post rock/ambient',
        buttonIcon: '#play',
        buttonText: 'Послушать',
      },
      table: {
        labels: ['Сервис', 'Прослушиваний', 'Стоимость', 'Сумма', 'Статус'],
        data: [
          ['Apple', 18658, 0.012, true],
          ['RMG', 51489, 0.005, true],
          ['Yandex', 5112, 0.08, false],
          ['Spotify', 1256, 0.034, true],
          ['Youtube', 7896, 0.005, false],
        ],
      },
      plans: [
        {
          iconSrc: require('@/assets/landing/icons/BASIC.png'),
          name: 'Basic',
          duration: {
            number: 0,
          },
          price: 0,
          subtitle: 'Базовые функции сервиса',
          details: [
            {
              text: 'Загрузка релизов на все площадки',
            },
            {
              text: 'Релиз осуществляется за 2 недели',
            },
            {
              text: 'Один релиз сингла или EP в месяц',
            },
            {
              text: 'Общая базовая статистика',
            },
            {
              text: 'Стандартная поддержка',
            },
            {
              text: 'Стопроцентные выплаты',
            },
          ],
          buttonText: 'Начать бесплатно',
          colors: {
            background: '#d00a09, #970807',
            name: '#ffffff',
            duration: 'rgba(255, 111, 110, .2)',
            subtitle: '#ff6161',
            hr: '#e70100',
            activeIcon: '#ff403f',
            button: '#f21818',
          },
        },
        {
          iconSrc: require('@/assets/landing/icons/SEMIHERO.png'),
          name: 'Semihero',
          duration: {
            number: 1,
            period: 'm',
          },
          price: 5,
          subtitle: 'Все функции сервиса',
          details: [
            {
              text: 'Все возможности плана <span>Basic</span>',
            },
            {
              text: 'Релиз на все площадки за 5 дней',
            },
            {
              text: 'Безлимитная загрузка релизов',
            },
            {
              text: 'Подробная статистика',
            },
            {
              text: 'Приоритетная поддержка',
            },
            {
              text: 'Создание пилейнов',
              info: 'Создание страницы лейбла на всех площадках и указание этого лайбла в качестве издателя на релизах',
            },
            {
              text: '20% экономии на подписке',
              inactive: true,
            },
          ],
          buttonText: 'Выбрать план',
          colors: {
            background: '#fe9724, #fb6a00',
            name: '#ffe970',
            duration: 'rgba(255, 207, 157, .3)',
            subtitle: '#ffe970',
            hr: '#ffa65e',
            activeIcon: '#ffe970',
            infoIcon: '#ffe970',
            inactive: '#b74d00',
            button: '#ffc600',
          },
        },
        {
          iconSrc: require('@/assets/landing/icons/SUPERHERO.png'),
          promo: 'Самый популярный',
          name: 'Superhero',
          duration: {
            number: 1,
            period: 'y',
          },
          price: 48,
          subtitle: 'Экономия 20%',
          details: [
            {
              text: 'Все возможности плана Basic',
            },
            {
              text: 'Релиз на все площадки за 5 дней',
            },
            {
              text: 'Безлимитная загрузка релизов',
            },
            {
              text: 'Подробная статистика',
            },
            {
              text: 'Приоритетная поддержка',
            },
            {
              text: 'Создание пилейнов',
              info: 'Создание страницы лейбла на всех площадках и указание этого лайбла в качестве издателя на релизах',
            },
            {
              text: '20% экономии на подписке',
            },
          ],
          buttonText: 'Выбрать план',
          colors: {
            background: '#235d85, #1c2948',
            name: '#30c5ff',
            duration: 'rgba(48, 197, 255, .2)',
            subtitle: '#30c5ff',
            hr: '#3e5687',
            activeIcon: '#30c5ff',
            info: '#30c5ff',
            button: '#30c5ff',
          },
        },

      ],
    };
  },
  computed: {
    tableTotal () {
      const row = [0, 0];
      for (let i = 0; i < this.table.data.length; i++) {
        row[0] += this.table.data[i][1];
        row[1] += (this.table.data[i][1] * this.table.data[i][2]);
      }
      return row;
    },
  },
  setup () {
    const labels = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', ''];
    const datapoints = [4373, 5237, 1297, 2476, 6235, 3135, 4564];

    function getGradient (ctx) {
      const gradient = ctx.createLinearGradient(0, 0, 0, 170);
      gradient.addColorStop(0, 'rgb(243, 36, 36, .4)');
      gradient.addColorStop(0.8, 'rgb(243, 36, 36, .1)');
      gradient.addColorStop(1, 'rgb(255, 255, 255, .1)');

      return gradient;
    }

    const data = {
      labels,
      datasets: [
        {
          data: datapoints,
          // fillColor : gradientFill,
          responsive: true,
          borderColor: '#f32424',
          fill: true,
          backgroundColor: function (context) {
            const { ctx } = context.chart;
            return getGradient(ctx);
          },
        },
      ],
    };

    const config = {
      type: 'line',
      data,
      options: {
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                return `${value / 1000}${value >= 1000 ? 'k' : ''}`;
              },
            },
          }],
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'white',
          // titleFontColor: '#f32424',
          bodyFontColor: '#f32424',
          bodyFontStyle: 'bold',
          callbacks: {
            title: (tooltipItems) => {
              return tooltipItems.yLabel;
            },
          },
        },
      },
    };

    return { config };
  },

  mounted () {
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    scrollTo (id, offset = 0) {
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + offset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/_nullstyle.scss';

.wrapper{
    width: 100%;
    background: #f5f5f5;
}

._container{
    margin: auto;
    max-width: 1570px;
    width: 100%;
    padding: 0 15px;
}
._container-narrow{
    margin: auto;
    max-width: 1040px;
    width: 100%;
    padding: 0 15px;
}

.text{
    &--common{
        color: $common;
    }
    &--superhero{
        color: $superhero;
    }
}

nav{
    cursor: pointer;
}

.header {
  height: 60px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  color: #000;
  position: relative;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
  }

  &__logo {
    height: 25px;
  }

  &__menu {
    display: flex;
    gap: 30px;
  }

  &__nav {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  &__actions {
    display: flex;
    gap: 15px;
  }

  &__actions a {
    text-decoration: none;
    font-weight: normal;
  }

  /* Стиль для текста "Вход" */
  &__login {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    cursor: pointer;
  }

  /* Мобильный стиль для иконки "Вход" */
  &__login--icon {
    display: none;
    font-size: 20px; /* Размер иконки входа */
  }

  /* Кнопка сэндвич-меню */
  &__toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 60px;
  }

  /* Мобильные стили */
  @media (max-width: 768px) {
    &__menu {
      position: fixed;
      top: 0;
      right: -100%; /* Скрыто за пределами экрана справа */
      height: 100%;
      width: 250px;
      background-color: #fff;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 60px;
      transition: right 0.3s ease;
      box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
      gap: 20px;
      padding-left: 20px;
    }

    &__menu--open {
      right: 0; /* Показываем меню, когда оно открыто */
      top: 60px;
      z-index: 9;
    }

    &__toggle {
      display: block;
    }

    /* Убираем текстовое представление кнопки "Вход" */
    &__login {
      display: none;
    }

    /* Показ иконки "Вход" */
    &__login--icon {
      display: inline;
    }

    /* Позиционирование иконки "Вход" справа от сэндвич-меню */
    &__toggle + .header__actions {
      display: flex;
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }
}

.banner {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--head{
        color: $white;
        background: url('../assets/landing/headBanner2x.jpeg');
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: center;
        background-size: cover;

        &__inner-wrapper{
            margin-left: 44%;
        }
    }

    &--ui{
        color: black;
        background: url('../assets/landing/uiBanner.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        margin-top: 23px;

        &__inner-wrapper{
            width: 50%;
        }
    }

    &__header {
        font-size: 1.5rem;

        &:not(:last-child){
            margin-bottom: 40px;
        }
    }

    &__text {
        font-size: 1.188rem;

        &:not(:last-child){
            margin-bottom: 22px;
        }
    }

    &__button{
        width: 140px;
        height: 35px;
        font-size: 1.188rem;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__listen{
        display: flex;
        gap: 10px;
        margin-bottom: 30px;

        & > *:not(:first-child){
            position: relative;
            padding-left: 10px;

            &::before{
                position: absolute;
                content: '';
                height: 20px;
                width: 1px;
                background: rgba($color: $white, $alpha: .2);
                left: 0;
                top: -1.5px;
            }
        }
    }

    &__artist{
        font-weight: 600;
    }

    &__source{

    }

    &__listen-button{
        background: transparent;
        border: none;
        color: $white;
        cursor: pointer;

        svg{
            fill: $white;
            width: 10px;
            height: 10px;
            margin-right: 6px;
        }
    }

    @media (max-width: 1200px) {
      &--head{
        background-position-x: 20%;
      }
      &__header {
        font-size: 1.2rem;
        &:not(:last-child){
          margin-bottom: 20px;
        }
      }
      &__text {
        font-size: 1.0rem;
      }
      &__listen{
        margin-bottom: 20px;
      }
    }

  @media (max-width: 768px) {
    &__inner-wrapper{
      margin-left: 5%;
    }
    &--head{
      background-position-x: 14%;
      height: 500px;
    }
    &__header {
      &:not(:last-child){
        margin-top: 130px;
      }
    }
  }

  @media (max-width: 320px) {
    &__inner-wrapper{
      margin-left: 3%;
      width: 95%;
    }
    &--head{
      background-position-x: 17%;
      height: 550px;
    }
    &__listen{
      align-content: center;
    }
  }

}

.glasses{
    &--small{
        z-index: 2;
        margin-top: -30px;
    }

    &--big{
        margin-top: 23px;

        .glass{
            p{
                width: 56%;
            }
        }
    }

    &__container{
        display: flex;
        flex-flow: row wrap;
        gap: 30px;
    }


    &__row{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        @media (max-width: 768px) {
          flex-flow: column;
        }
    }

    @media (max-width: 768px) {
      &--big{
        .glass{
          p{
            width: 61%;
          }
        }
      }
    }

  @media (max-width: 320px) {
    &--big{
      .glass{
        p{
          width: 92%;
        }
      }
    }
  }

}

.glass{
    flex: 1;

    &--wide{
        flex: 2.25;

        .glass__content{
            p{
                max-width: 500px;
            }

            & > *:not(:last-child){
                margin-bottom: 20px;
            }
        }
    }

    &__platforms{
        display: flex;
        flex-flow: row wrap;

        img{
            width: 70px;
            height: 70px;

            &:not(:last-child){
                margin-right: 15px;
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: 768px) {
      &__platforms {
        margin: 20px 0;
      }
    }

}

.infographics {
    display: flex;
    flex-direction: row;
    gap: 30px;

    &__block {
        flex: 1;
        height: 250px;
        box-shadow: 0 0 21px rgba($color: black, $alpha: .1);
        border-radius: 20px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &--chart{
            padding: 24px 32px;
        }

        &--table{
            padding: 22px 15px;
        }
    }

    &__header{
        width: 100%;
        padding: 0 8px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 15px;

        h5{
            font-weight: 500;
        }
    }

    &__table{
        border-collapse: collapse;

        th{
            text-align: start;
        }

        tr{
            height: 27px;
            transition: .1s;
            &:not(:last-child){
                border-bottom: 1px solid $dark-gray;
            }
            &:not(:last-child):hover{
                cursor: pointer;
                background: $gray;
                transition: .2s;
            .infographics__status{
                    &--paid{
                        background: #77a768;
                        transition: .2s;
                    }
                    &--unpaid{
                        background: #ce8f67;
                        transition: .2s;
                    }
                }
            }
        }

        td{
            padding-left: 4px;
        }

        thead {
            border-bottom: 1px solid $dark-gray;

            th{
                color: $dark-gray;
                padding-bottom: 7px;
                font-weight: 500;
            }
        }

        width: 95%;
        font-size: 12px;
    }

    &__status{
        padding: 3px 5px;
        color: $white;
        border-radius: 5px;
        font-weight: 600;
        font-size: 11px;

        &--paid{
            background: $common;
            transition: .1s;
        }
        &--unpaid{
            background: $superhero;
            transition: .1s;
        }
    }
}

.chart{
    height: 95%;
    width: 100%;
}

.footer {
    height: 140px;
    display: flex;
    align-items: center;
    color: #82868a;
    a{
        color: inherit;
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__info {
        & > *:not(:last-child){
            margin-bottom: 20px;
        }
    }

    // &__documents {}

    // &__copyright {}

    &__social-media {
        max-width: 50%;
    }

    &__links{
        display: flex;
        flex-flow: row wrap;
        column-gap: 20px;
        row-gap: 10px;
    }

    &__link{
        text-decoration: none;
    }

    @media (max-width: 768px) {
      &__container {
        flex-direction: column;
      }
      &__links{
        justify-content: center;
      }

      &__social-media {
        max-width: 100%;
        margin-top: 20px;
      }
    }
}
.interface {
    @media (max-width: 768px) {
      background-position: 85% center;

      ._container-narrow{
        background: rgba(230, 229, 229, 0.7);
        height: 100%;
        display: flex;
        justify-content: center;
        align-self: center;
      }

      .banner__inner-wrapper{
        width: 90%;
        align-self: center;
      }
    }
}
</style>
