<template>
  <div class="page">
    <ReleasePreview :releaseUuid="$route.params.uuid" />
  </div>
</template>

<script>
import ReleasePreview from "@/components/releasePreview/ReleasePreview.vue";

export default {
  components: {
    ReleasePreview,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";

.page {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>