<template>
  <div
    :class="[
      'chip',
      'chip--' + color,
      { stroke: $attrs.stroke != undefined },
      { small: $attrs.small != undefined },
      { quadrat: $attrs.quadrat != undefined },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.chip {
  padding: 6px 12px;
  border-radius: 100px;
  width: min-content;

  &--gray {
    color: #959595;
    background: #d7d7d7;
    border: 1px solid #959595;
  }

  &--red {
    color: #e85a5a;
    background: #ffc1c1;
    border: 1px solid #e85a5a;
  }

  &--orange {
    color: #c69448;
    background: #ffcf88;
    border: 1px solid #c69448;
  }

  &--yellow {
    color: #e4b846;
    background: #ffeab2;
    border: 1px solid #e4b846;
  }

  &--green {
    color: #71cd69;
    background: #d8ffdc;
    border: 1px solid #71cd69;
  }
}

.stroke {
  background: transparent;
  border-width: 2px;
  font-weight: 600;
}

.small {
  padding: 3px 6px 1px 6px;
  font-size: 13px;
  text-transform: uppercase;
}

.quadrat{
  border-radius: 7px;
}
</style>
