<template>
  <div :class="['track', { loading }]">
    <div class="preview" @click="toggleOpen()">
      <div class="index-wrapper">
        <PlayingBarsAnim v-show="playing && !loading" />
        <span class="index" v-show="!playing || loading"> {{ index }} </span>
      </div>
      <div class="data">
        <div class="track-name">
          <span class="name">{{ track?.title }}</span>
          <span class="title-version">{{ track?.title_version }}</span>
        </div>
        <div class="artists">
          <div class="explicit" v-show="track?.explicit">E</div>
          {{ primaryArtists }}
          <EmptyText
            v-if="!primaryArtists && !loading"
            :text="'without artists'"
            :tooltip="
              releaseStatus != 'DRAFT'
                ? 'Please contact support it must be some kind of error. Each track must have at least 1 primary artist!'
                : ''
            "
          />
        </div>
      </div>
      <span class="loader" v-if="audioLoading && !loading"></span>
      <span class="time" v-if="!audioLoading && !loading && !errorDownloadWav">
        {{ duration }}
      </span>
      <div
        class="actions"
        v-if="!audioLoading && !loading && !errorDownloadWav"
      >
        <Button small icononly @click.stop="$emit('play')" v-show="!playing">
          <template v-slot:pre-icon>
            <SvgIcon type="mdi" :path="icons.play" />
          </template>
        </Button>
        <Button small icononly @click.stop="$emit('pause')" v-show="playing">
          <template v-slot:pre-icon>
            <SvgIcon type="mdi" :path="icons.pause" />
          </template>
        </Button>
      </div>
      <div class="error-file-download" v-show="errorDownloadWav && !loading">
        <SvgIcon type="mdi" :path="icons.warn" />
        <div class="tooltip">
          <Tooltip>An error occurred while uploading the file</Tooltip>
        </div>
      </div>
    </div>
    <div class="meta-data" v-show="isOpen">
      <div class="genre">
        <h4 class="title">{{ $t("genre") }}</h4>
        <p class="text">
          {{ primaryStyle(this.track?.primary_music_style_id)?.name }}
        </p>
      </div>
      <div class="language">
        <h4 class="title">{{ $t("language") }}</h4>
        <p class="text">{{ language(this.track?.language_id)?.name }}</p>
      </div>
      <div class="title-version">
        <h4 class="title">{{ $t("release_title_version") }}</h4>
        <p class="text">{{ this.track?.title_version }}</p>
      </div>
      <div class="preview-start">
        <h4 class="title">{{ $t("preview_start_time") }}</h4>
        <p class="text">{{ previewStart }}</p>
      </div>
      <div class="lyrics">
        <h4 class="title">{{ $t("lyrics") }}</h4>
        <pre class="lyrics-text" ref="lyricsText" v-if="track?.lyrics">{{
          track?.lyrics
        }}</pre>
        <EmptyText v-if="!track?.lyrics && !loading" :text="'no lyrics'" />
      </div>
      <div class="explicit">
        <h4 class="title">Explicit</h4>
        <div class="bool-status bool-status__false" v-if="!track?.explicit">
          <SvgIcon type="mdi" :path="icons.false" />
          {{ $t("no") }}
        </div>
        <div class="bool-status bool-status__true" v-else>
          <SvgIcon type="mdi" :path="icons.true" />
          {{ $t("yes") }}
        </div>
      </div>
      <div class="instrumental">
        <h4 class="title">{{ $t("instrumental") }}</h4>
        <div class="bool-status bool-status__false" v-if="!track?.instrumental">
          <SvgIcon type="mdi" :path="icons.false" />
          {{ $t("no") }}
        </div>
        <div class="bool-status bool-status__true" v-else>
          <SvgIcon type="mdi" :path="icons.true" />
          {{ $t("yes") }}
        </div>
      </div>
      <div class="artists-authors">
        <div class="artists">
          <h4 class="title">{{$t('artists')}}</h4>
          <div class="artist" v-for="artist in track?.artists" :key="artist">
            <div class="avatar">
              <img
                :src="
                  artist.avatarCdnLink
                    ? artist.avatarCdnLink
                    : '/default-user.png'
                "
                alt=""
              />
            </div>
            <div class="name">{{ artist.name }}</div>
            <div class="role">— {{ $t(`artist_roles.${artist.role}`) }}</div>
          </div>
          <EmptyText
            v-if="(!track?.artists || !track?.artists.length) && !loading"
            :text="'without artists'"
            :tooltip="
              releaseStatus != 'DRAFT'
                ? 'Please contact support it must be some kind of error. Each track must have at least 1 primary artist!'
                : ''
            "
          />
        </div>
        <div class="authors">
          <h4 class="title">{{$t('authors')}}</h4>
          <div
            class="author"
            v-for="author in track?.composers"
            :key="author.uuid"
          >
            <div class="role text">{{ $t(`composer_roles.${author.role}`) }}</div>
            <div class="name text">{{ author.name }}</div>
            <div class="surname text">{{ author.surname }}</div>
            <div class="patronymic text">{{ author.patronymic }}</div>
          </div>
          <EmptyText
            v-if="(!track?.composers || !track?.composers.length) && !loading"
            :text="'without authors'"
            :tooltip="
              releaseStatus != 'DRAFT'
                ? 'Please contact support it must be some kind of error. Each track must have at least 1 composer!'
                : ''
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import PlayingBarsAnim from "./PlayingBarsAnim.vue";
import {
  mdiWindowClose,
  mdiCheck,
  mdiPlay,
  mdiPause,
  mdiSimAlert,
} from "@mdi/js";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Button from "@/components/RedButton.vue";
import EmptyText from "./EmptyText.vue";
import Tooltip from "./Tooltip.vue";

export default {
  components: {
    SvgIcon,
    Button,
    PlayingBarsAnim,
    EmptyText,
    Tooltip,
  },
  props: {
    index: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
    track: {
      type: Object,
    },
    playing: {
      type: Boolean,
    },
    releaseStatus: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        false: mdiWindowClose,
        true: mdiCheck,
        play: mdiPlay,
        pause: mdiPause,
        warn: mdiSimAlert,
      },
      wavCdnLink: "",
      audioLoading: false,
      durationSeconds: 0,
      isOpen: false,
      errorDownloadWav: false,
    };
  },
  mounted() {
    const lyricsDiv = this.$refs.lyricsText;

    // Проверяем, существует ли элемент
    if (lyricsDiv) {
      const lineHeight = parseFloat(getComputedStyle(lyricsDiv).lineHeight);

      lyricsDiv.addEventListener("wheel", (event) => {
        event.preventDefault();
        const direction = event.deltaY > 0 ? 1 : -1;
        lyricsDiv.scrollTop += direction * lineHeight;
      });
    }

    // Проверяем и загружаем необходимые данные
    if (!this.language(this.track?.language_id)) {
      this.fetchLanguages();
    }
    if (!this.primaryStyle(this.track?.primary_music_style_id)) {
      this.fetchMusicStyles();
    }

    this.audioLoading = true;
    this.getFileCdnLink(this.track?.wav_uuid)
      .then((response) => {
        this.wavCdnLink = response.data;

        fetch(this.wavCdnLink)
          .then((response) => response.blob())
          .then((blob) => {
            const audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();

            const reader = new FileReader();
            reader.onloadend = () => {
              audioContext.decodeAudioData(
                reader.result,
                (audioBuffer) => {
                  this.durationSeconds = audioBuffer.duration; // Длительность трека в секундах

                  this.$emit("loaded", {
                    blob,
                    buffer: audioBuffer,
                    duration: audioBuffer.duration,
                  });
                  this.audioLoading = false;
                },
                (error) => {
                  // Обработка ошибки при декодировании аудиоданных
                  console.error("Ошибка декодирования аудиоданных:", error);
                  this.errorDownloadWav = true;
                  this.audioLoading = false;
                  this.$emit("error");
                }
              );
            };
            reader.onerror = () => {
              this.audioLoading = false;
              this.errorDownloadWav = true;
              this.$emit("error");
            };
            reader.readAsArrayBuffer(blob); // Читаем файл как ArrayBuffer
          })
          .catch((error) => {
            console.error("Ошибка загрузки файла:", error);
            this.audioLoading = false;
            this.errorDownloadWav = true;
            this.$emit("error");
          });
      })
      .catch(() => {
        this.audioLoading = false;
        this.errorDownloadWav = true;
        this.$emit("error");
      });
  },
  computed: {
    ...mapGetters(["artistRoles", "language", "primaryStyle"]),

    primaryArtists() {
      const artists = this.track?.artists.filter(
        (artist) => artist.role == this.artistRoles.PRIMARY_ARTIST
      );
      if (artists) {
        if (artists && artists.length > 1) {
          return (
            artists[0].name +
            ", " +
            artists
              .map((artist) => artist.name)
              .slice(1, 4)
              .join(", ")
          );
        } else {
          return artists[0]?.name;
        }
      }
    },

    previewStart() {
      const previewStartSeconds = this.track?.preview_start_seconds ?? 0;
      return this.formatAudioTimeString(previewStartSeconds);
    },

    duration() {
      const duration = this.durationSeconds ?? 0;
      return this.formatAudioTimeString(parseInt(duration));
    },
  },
  methods: {
    ...mapActions(["fetchMusicStyles", "fetchLanguages", "getFileCdnLink"]),

    toggleOpen() {
      this.isOpen = !this.isOpen;
    },

    formatAudioTimeString(sec) {
      const minutes = Math.floor(sec / 60);
      const seconds = sec % 60;

      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = seconds.toString().padStart(2, "0");

      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";
.track {
  background: #f4f4f4;
  border-radius: 16px;
  margin-bottom: 10px;

  .preview {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 16px 24px;

    .error-file-download {
      position: relative;
      width: 35px;
      height: 35px;
      background: $orange;
      color: white;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      .tooltip {
        top: -20px;
        transform: translateX(-100%);
        position: absolute;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    .index-wrapper {
      width: 30px;
    }

    .actions {
      display: none;
    }

    &:hover {
      .actions {
        display: block;
      }

      .time {
        display: none;
      }
    }

    .index-wrapper {
      .index {
        margin-right: 20px;
        color: #82868a;
        min-width: 18px;
      }
    }

    .data {
      flex-grow: 1;

      .track-name {
        color: black;
        font-size: 18px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        .name {
          display: inline-block;
          max-width: 75%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .title-version {
          margin-left: 5px;
          max-width: 20%;
          color: #a0a0a0;
          white-space: nowrap;
        }
      }

      .artists {
        color: #82868a;
        display: flex;

        .explicit {
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 4px;
          width: 16px;
          height: 16px;
          background: black;
          color: white;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 600;
          border-radius: 4px;
          margin-right: 4px;
        }
      }
    }

    .time {
      margin-left: auto;
      color: #82868a;
    }
  }

  .meta-data {
    padding: 16px 24px;
    border-top: 1px solid #c7c7c7;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 40px 40px auto;
    grid-template-areas:
      "genre language title-version preview-start"
      "lyrics lyrics  explicit      instrumental "
      "lyrics lyrics  artists-authors       artists-authors"
      "lyrics lyrics  artists-authors       artists-authors"
      "lyrics lyrics  artists-authors       artists-authors";
    .genre {
      grid-area: genre;
    }
    .language {
      grid-area: language;
    }
    .title-version {
      grid-area: title-version;
    }
    .preview-start {
      grid-area: preview-start;
    }
    .lyrics {
      grid-area: lyrics;

      .empty-data {
        color: #a0a0a0;
      }
    }
    .explicit {
      grid-area: explicit;
    }
    .instrumental {
      grid-area: instrumental;
    }
    .artists-authors {
      grid-area: artists-authors;
      display: flex;
      flex-direction: column;

      .artists {
        margin-bottom: 20px;
        .artist {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          .name {
            font-size: 18px;
            font-weight: 600;
            margin-right: 5px;
            max-width: 35%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .role {
            color: #a0a0a0;
            text-transform: capitalize;
          }

          .avatar {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 5px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .authors {
        margin-bottom: 20px;

        .author {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas:
            "role role role"
            "name surname patronymic";
          align-items: center;
          margin-bottom: 5px;
          gap: 0px 5px;
          .text {
            font-size: 18px;
            font-weight: 600;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .name {
            grid-area: name;
          }

          .surname {
            grid-area: surname;
          }

          .patronymic {
            grid-area: patronymic;
          }

          .role {
            grid-area: role;
            color: $orange;
            text-transform: capitalize;
            font-size: 12px;
            margin-bottom: 3px;
          }
        }
      }
    }

    .title {
      color: #82868a;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
    }

    .text {
      margin: 0;
      font-weight: 600;
      color: black;
    }

    .bool-status {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      svg {
        margin-right: 5px;
      }

      &__false {
        svg {
          color: $orange;
        }
      }

      &__true {
        svg {
          color: $orange;
        }
      }
    }
  }
}

.lyrics-text {
  white-space: pre-wrap;
  font-family: sans-serif;
  max-height: 400px;
  font-size: 16px;
  overflow-y: scroll;
  line-height: 1.5;
}

.loading {
  .meta-data {
    display: none;
  }

  .preview {
    .index-wrapper {
      .index {
        display: inline-block;
        color: rgba(0, 0, 0, 0);
        min-width: 18px;
        height: 18px;
        -webkit-animation: gradient-move 3s ease infinite;
        -moz-animation: gradient-move 3s ease infinite;
        animation: gradient-move 3s ease infinite;

        border-radius: 6px;
        background: linear-gradient(45deg, #c7c7c7, #e9e9e9);
        background-size: 300% 300%;
      }
    }

    .data {
      .track-name {
        color: rgba(0, 0, 0, 0);
        -webkit-animation: gradient-move 3s ease infinite;
        -moz-animation: gradient-move 3s ease infinite;
        animation: gradient-move 3s ease infinite;

        border-radius: 6px;
        background: linear-gradient(45deg, #c7c7c7, #e9e9e9);
        background-size: 300% 300%;

        height: 18px;

        .title-version {
          display: none;
        }
      }

      .artists {
        white-space: nowrap;
        max-width: 100px;
        color: rgba(0, 0, 0, 0);
        -webkit-animation: gradient-move 3s ease infinite;
        -moz-animation: gradient-move 3s ease infinite;
        animation: gradient-move 3s ease infinite;

        border-radius: 6px;
        background: linear-gradient(45deg, #c7c7c7, #e9e9e9);
        background-size: 300% 300%;

        height: 18px;

        .explicit {
          display: none;
        }
      }
    }

    .time {
      display: none;
    }
  }
}

.loader {
  width: 23px;
  height: 23px;
  border: 2px solid #a0a0a0;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .track {
    .preview {
      padding: 12px 20px;
      justify-content: space-between;

      .error-file-download {
        min-width: 30px;
        width: 30px;
        height: 30px;
      }

      .index-wrapper {
        width: 30px;

        .index {
          margin-right: 20px;
          color: #82868a;
          min-width: 18px;
        }
      }

      .data {
        flex-grow: 1;
        max-width: 80%;
        margin-right: auto;

        .track-name {
          font-size: 16px;
          width: 85%;

          .title-version {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .artists {
          color: #82868a;
          display: flex;
          font-size: 14px;
        }
      }

      .time {
        margin-left: auto;
        color: #82868a;
      }
    }

    .meta-data {
      padding: 16px 24px;
      border-top: 1px solid #c7c7c7;
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 40px 40px auto;
      grid-template-areas:
        "genre language title-version"
        "preview-start  explicit  instrumental"
        "artists-authors artists-authors artists-authors"
        "artists-authors artists-authors artists-authors"
        "lyrics lyrics lyrics"
        "lyrics lyrics lyrics";
      .genre {
        grid-area: genre;
      }
      .language {
        grid-area: language;
      }
      .title-version {
        grid-area: title-version;
      }
      .preview-start {
        grid-area: preview-start;
      }
      .lyrics {
        grid-area: lyrics;

        .empty-data {
          color: #a0a0a0;
        }
      }
      .explicit {
        grid-area: explicit;
      }
      .instrumental {
        grid-area: instrumental;
      }
      .artists-authors {
        grid-area: artists-authors;
        display: flex;
        flex-direction: column;

        .artists {
          margin-bottom: 10px;
          .artist {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .name {
              font-size: 14px;
              font-weight: 500;
              margin-right: 5px;
              max-width: 60%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .role {
              color: #a0a0a0;
              text-transform: capitalize;
            }

            .avatar {
              width: 20px;
              height: 20px;
              border-radius: 100%;
              overflow: hidden;
              margin-right: 5px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        .authors {
          margin-bottom: 0px;

          .author {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
              "role role role"
              "name surname patronymic";
            align-items: center;
            margin-bottom: 5px;
            gap: 0px 5px;
            .text {
              font-size: 14px;
              font-weight: 500;
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .name {
              grid-area: name;
            }

            .surname {
              grid-area: surname;
            }

            .patronymic {
              grid-area: patronymic;
            }

            .role {
              grid-area: role;
              color: $orange;
              text-transform: capitalize;
              font-size: 12px;
              margin-bottom: 3px;
            }
          }
        }
      }

      .title {
        color: #82868a;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
      }

      .text {
        margin: 0;
        font-weight: 600;
        color: black;
      }

      .bool-status {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        svg {
          margin-right: 5px;
        }

        &__false {
          svg {
            color: $orange;
          }
        }

        &__true {
          svg {
            color: $orange;
          }
        }
      }
    }
  }
}

@media (max-width: 475px) {
  .track {
    .preview {
      padding: 12px 20px;
      justify-content: space-between;

      .error-file-download {
        min-width: 30px;
        width: 30px;
        height: 30px;
      }

      .index-wrapper {
        width: 30px;

        .index {
          margin-right: 20px;
          color: #82868a;
          min-width: 18px;
        }
      }

      .data {
        flex-grow: 1;
        max-width: 80%;
        margin-right: auto;

        .track-name {
          font-size: 16px;
          width: 85%;

          .title-version {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .artists {
          color: #82868a;
          display: flex;
          font-size: 14px;
        }
      }

      .time {
        margin-left: auto;
        color: #82868a;
      }
    }

    .meta-data {
      padding: 16px 24px;
      border-top: 1px solid #c7c7c7;
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 40px 40px auto;
      grid-template-areas:
        "genre language"
        "title-version preview-start"
        "explicit  instrumental"
        "artists-authors artists-authors"
        "artists-authors artists-authors"
        "lyrics lyrics"
        "lyrics lyrics";
      .genre {
        grid-area: genre;
      }
      .language {
        grid-area: language;
      }
      .title-version {
        grid-area: title-version;
      }
      .preview-start {
        grid-area: preview-start;
      }
      .lyrics {
        font-weight: 400;
        font-size: 14px;
        grid-area: lyrics;

        .empty-data {
          color: #a0a0a0;
        }
      }
      .explicit {
        grid-area: explicit;
      }
      .instrumental {
        grid-area: instrumental;
      }
      .artists-authors {
        grid-area: artists-authors;
        display: flex;
        flex-direction: column;

        .artists {
          margin-bottom: 20px;
          .artist {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .name {
              font-size: 14px;
              font-weight: 500;
              margin-right: 5px;
              max-width: 60%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .role {
              color: #a0a0a0;
              text-transform: capitalize;
            }

            .avatar {
              width: 20px;
              height: 20px;
              border-radius: 100%;
              overflow: hidden;
              margin-right: 5px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        .authors {
          margin-bottom: 20px;

          .author {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
              "role role role"
              "name surname patronymic";
            align-items: center;
            margin-bottom: 5px;
            gap: 0px 5px;
            .text {
              font-size: 14px;
              font-weight: 500;
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .name {
              grid-area: name;
            }

            .surname {
              grid-area: surname;
            }

            .patronymic {
              grid-area: patronymic;
            }

            .role {
              grid-area: role;
              color: $orange;
              text-transform: capitalize;
              font-size: 12px;
              margin-bottom: 3px;
            }
          }
        }
      }

      .title {
        color: #82868a;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
      }

      .text {
        margin: 0;
        font-weight: 600;
        color: black;
      }

      .bool-status {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        svg {
          margin-right: 5px;
        }

        &__false {
          svg {
            color: $orange;
          }
        }

        &__true {
          svg {
            color: $orange;
          }
        }
      }
    }
  }
}
</style>
