<template>
  <div class="empty-track">
    <div class="message">There are no tracks on this release</div>
    <Button small v-show="releaseStatus ==  'DRAFT'" @click="goToDraft"> add a track 
      <template v-slot:post-icon>
        <SvgIcon type="mdi" :path="icons.add" />
      </template>
    </Button>
  </div>
</template>

<script>
import Button from "../RedButton.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMusicNotePlus } from '@mdi/js';
export default {
  components: {
    Button,
    SvgIcon
  },
  props:{
    releaseStatus: String,
    releaseUuid: String
  },
  data(){
    return{
      icons:{
        add: mdiMusicNotePlus
      }
    }
  },
  methods:{
    goToDraft(){
      this.$router.push(`/drafts/${this.releaseUuid}`)
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-track {
  box-sizing: border-box;
  min-height: 73px;
  width: 100%;
  background: #f4f4f4;
  border-radius: 16px;
  margin-bottom: 10px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .message {
    font-size: 18px;
    font-weight: 600;
    color: #82868a;
  }
}
</style>