<template>
  <button
    :class="[
      'red-button',
      { small: $attrs.small != undefined },
      { transparent: $attrs.transparent != undefined },
      { loading: loading },
      { gray: $attrs.gray != undefined },
      { icononly: $attrs.icononly != undefined },
    ]"
  >
    <div class="pre-icon icon">
      <slot name="pre-icon"></slot>
    </div>
    <slot />
    <div class="post-icon icon">
      <slot name="post-icon"></slot>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_shared.scss";

.red-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  background: $red;
  color: $white;
  border: none;
  border-radius: $button_border-radius;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  transition: 0.1s;
  height: min-content;
  white-space: nowrap;

  &:enabled:hover {
    transform: translateY(-2px);
    transition: 0.2s;
    background: rgb(212, 23, 23);
  }

  &:enabled:active {
    transform: translateY(1px);
  }

  &:disabled {
    background: $gray;
    color: $dark-gray;
    cursor: not-allowed;
  }
}

.small {
  height: min-content;
  padding: 5;
  height: 36px;
  min-width: 36px;
  width: min-content;
}

.gray {
  background: $gray;

  &:enabled:hover {
    transform: translateY(-2px);
    transition: 0.2s;
    background: rgb(193, 193, 193);
  }

  &:enabled:active {
    transform: translateY(1px);
  }

  &:disabled {
    background: $gray;
    color: $dark-gray;
    cursor: not-allowed;
  }
}

.transparent {
  background: transparent;
  font-weight: 500;
  padding: 10px 0px;
  color: red;

  &:enabled:hover {
    transform: translateY(-2px);
    transition: 0.2s;
    background: transparent;
  }
}

.gray {
  color: #82868a;
}

.icon{
  height: 24px;
}

.pre-icon {
  margin-right: 10px;
}
.post-icon {
  margin-left: 10px;
}

.icononly{
  padding: 0;
  svg{
    width: 20px;
    height: 20px;
  }
  .pre-icon{
    margin: 0;
  }
  .post-icon{
    margin: 0;
  }
}
</style>
