<template>
  <div class="playing">
    <span class="playing__bar playing__bar1"></span>
    <span class="playing__bar playing__bar2"></span>
    <span class="playing__bar playing__bar3"></span>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/_shared.scss";
@import "@/assets/_nullstyle.scss";
.playing {
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
}

.playing__bar {
  display: inline-block;
  background: $red;
  width: 30%;
  height: 100%;
  animation: up-and-down 2s ease infinite alternate;
}

.playing__bar1 {
  height: 60%;
}

.playing__bar2 {
  height: 30%;
  animation-delay: -2.2s;
}

.playing__bar3 {
  height: 75%;
  animation-delay: -3.7s;
}

@keyframes up-and-down {
  10% {
    height: 30%;
  }

  30% {
    height: 100%;
  }

  60% {
    height: 50%;
  }

  80% {
    height: 75%;
  }

  100% {
    height: 60%;
  }
}

body {
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: sans-serif;
  color: white;
}
</style>