<template>
  <div class="tooltip"><slot /></div>
</template>
<style lang="scss" scoped>
.tooltip {
  display: none;
  color: #000000;
  background: rgba($color: white, $alpha: 0.5);
  backdrop-filter: blur(7px);
  border: 1px dotted rgba($color: #000000, $alpha: 0.5);
  width: 200px;
  border-radius: 3px;
  padding: 8px;
  font-size: 15px;
  font-weight: 500;
}
</style>