import axios from 'axios';
import { getParsedToken } from '../../utilities/getParsedToken';
import { PurchaseType } from '../../utilities/purchases';

const initialState = () => {
  return {
    isPaymentModal: false,
    cartPurchases: [],

    userProducts: [],
    purchases: [],
    productAccesses: [],

    subscriptions: [],
    products: [],
  };
};

const payments = {
  state: () => initialState(),
  actions: {
    showPaymentModal: function ({ commit }) {
      commit('setIsPaymentModal', true);
    },

    hidePaymentModal: function ({ commit }) {
      commit('setIsPaymentModal', false);
    },

    togglePaymentModal: function ({ state, commit }) {
      const value = !state.isPaymentModal;
      commit('setIsPaymentModal', value);
    },

    setIsPaymentModal: function ({ state, commit }, value) {
      commit('setIsPaymentModal', value);
    },

    fetchUserProduct: function ({ commit }, userProductUuid) {
      return new Promise((resolve, reject) => {
        axios.get(`/user-product/${userProductUuid}`)
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },

    fetchPurchases: function ({ commit }) {
      // todo: let user ability to change date range
      const today = new Date();

      const startDate = new Date(today);
      startDate.setFullYear(today.getFullYear() - 3);

      const endDate = new Date(today);
      endDate.setFullYear(today.getFullYear() + 3);

      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        order: 'DESC',
      };

      return new Promise((resolve, reject) => {
        axios.get('/me/purchases', { params })
          .then(response => {
            commit('setPurchases', response.data.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },

    fetchUserProductAccess ({ commit }, productName) {
      return new Promise((resolve, reject) => {
        axios.get(`/check/${productName}`).then(response => {
          if (response.data?.status == 'PAID') {
            commit('addProductAccess', productName);
          }
          resolve(response);
        }).catch(e => reject(e));
      });
    },

    createUserSubscription: function ({ commit }, subscriptionName) {
      const today = new Date();
      const startAt = new Date(today);
      const expiresAt = new Date(today);
      expiresAt.setMonth(expiresAt.getMonth() + 1);

      const userUuid = getParsedToken().sub;
      const payload = {
        subscriptionName,
        userUuid,
        startAt: startAt.toISOString(),
        expiresAt: expiresAt.toISOString(),
      };

      return new Promise((resolve, reject) => {
        axios.post('user-subscription', payload).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.SUBSCRIPTION,
          };
          commit('addUserProduct', userPurchase);
          commit('setCartPurchases', [userPurchase]);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkoutUserSubscription: function ({ commit }, { userSubscriptionUuid, paymentType }) {
      return new Promise((resolve, reject) => {
        axios.get(`user-subscription/${userSubscriptionUuid}/checkout`, {
          params: {
            paymentType,
          },
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchUserProducts: function ({ commit }) {
      const userUuid = getParsedToken().sub;

      return new Promise((resolve, reject) => {
        axios.get(`/user-product/user/${userUuid}`)
          .then(response => {
            commit('setUserProducts', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      });
    },
    createUserProduct: function ({ commit }, productName) {
      const userUuid = getParsedToken().sub;
      const payload = {
        productName,
        userUuid,
      };

      return new Promise((resolve, reject) => {
        axios.post('user-product', payload).then(response => {
          const userPurchase = {
            ...response.data,
            type: PurchaseType.PRODUCT,
          };
          commit('addUserProduct', userPurchase);
          commit('setCartPurchases', [userPurchase]);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    checkoutUserProduct: function ({ commit }, { userProductUuid, paymentType }) {
      return new Promise((resolve, reject) => {
        axios.get(`user-product/${userProductUuid}/checkout`, {
          params: {
            paymentType,
          },
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    fetchProducts ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/products').then(response => {
          commit('setProducts', response.data);
          resolve(response);
        }).catch(e => reject(e));
      });
    },
    fetchSubscriptions ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/subscriptions').then(response => {
          commit('setSubscriptions', response.data);
          resolve(response);
        }).catch(e => reject(e));
      });
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, initialState());
    },

    setProducts (state, products) {
      state.products = products;
    },
    setSubscriptions (state, subscriptions) {
      state.subscriptions = subscriptions;
    },

    addProductAccess: function (state, productName) {
      state.productAccesses.push(productName);
    },

    setIsPaymentModal: function (state, value) {
      state.isPaymentModal = value;
    },

    setPurchases: function (state, purchases) {
      state.purchases = purchases;
    },

    setUserProducts: function (state, userProducts) {
      state.userProducts = userProducts;
    },
    addUserProduct: function (state, userProduct) {
      state.userProducts.push(userProduct);
    },
    removeUserProduct: function (state, uuid) {
      state.userProducts = state.userProducts.filter(p => p.uuid != uuid);
    },

    setCartPurchases: function (state, userProducts) {
      state.cartPurchases = userProducts;
    },
    addUserProductToCart: function (state, userProduct) {
      state.cartPurchases.push(userProduct);
    },
    removeUserProductFromCart: function (state, uuid) {
      state.cartPurchases = state.cartPurchases.filter(p => p.uuid != uuid);
    },
  },
  getters: {
    isPaymentModal: state => state.isPaymentModal,
    cartPurchases: state => state.cartPurchases,

    purchases: state => state.purchases,
    purchase: state => uuid => state.purchases.find((p) => p.uuid == uuid),
    purchasesByStatus: state => status => state.purchases.filter((p) => p.status == status),

    userProducts: state => state.userProducts,
    userProduct: state => uuid => state.userProducts.find((p) => p.uuid == uuid),
    userProductsByStatus: state => status => state.userProducts.filter((p) => p.status == status),

    productAccesses: state => state.productAccesses,
    productAccess: state => productName => state.productAccesses.find((p) => p == productName),

    products: state => state.products,
    subscriptions: state => state.subscriptions,
  },
};

export default payments;
